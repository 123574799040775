import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  createRef,
} from 'react';
import PropTypes, { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ViewportDownloadForm.css';
import './ViewportDownloadForm.styl';
import { TextInput, Select, Icon } from '@dcmcloud/ui';
import classnames from 'classnames';
import axios from 'axios';
import { KeyImages } from '../../../../../../Constants/baseURL';

const FILE_TYPE_OPTIONS = [
  {
    key: 'jpg',
    value: 'jpg',
  },
  {
    key: 'png',
    value: 'png',
  },
];

const DEFAULT_FILENAME = 'image';
const REFRESH_VIEWPORT_TIMEOUT = 1000;
var studyInstanceId = localStorage.getItem('StudyInstanceUID');
// console.log(studyInstanceId, 'studyInstanceIdstudyInstanceId');

//   console.log(buff, 'text');
//   let text = buff.toString();
//   return text;
// }
function getAccessKeys() {
  var urlArray = window.location.href.split('/');
  // console.log(urlArray.length, 'urlArray');
  var keyTypeArray = urlArray[urlArray.length - 1];
  var moreSplit1 = keyTypeArray.split('%5E')[1];
  // console.log(moreSplit1, 'moreSplit1moreSplit1moreSplit1');
  // console.log(keyTypeArray.split('%5E').length, "keyTypeArray.split('%5E')[1]");
  let buff = Buffer.from(moreSplit1, 'base64');
  let text = buff.toString();
  // console.log(text, 'texttexttexttext');
  return text;
}

function getKey() {
  var urlArray = window.location.href.split('/');
  // console.log(urlArray, 'urlArrayurlArray');

  if (urlArray.length > 5) {
    var keyTypeArray = urlArray[urlArray.length - 1];
    var Xrad = urlArray[urlArray.length - 3];
    var XradFs = urlArray[urlArray.length - 4];
    var keyFolder = urlArray[urlArray.length - 2];
    var moreSplit =
      XradFs + '/' + Xrad + '/' + keyFolder + '/' + keyTypeArray.split('%5')[0];
    // console.log(
    //   keyFolder,
    //   'keyFolder',
    //   keyTypeArray,
    //   ' keyTypeArray',
    //   moreSplit,
    //   'keyTypeArray'
    // );
  } else {
    var keyTypeArray = urlArray[urlArray.length - 1];
    var moreSplit = keyTypeArray.split('%5')[0];
    // console.log(moreSplit, 'moresplitmoresplit');
  }
  return moreSplit;
}

function loadUploadedImage() {
  document.getElementById('uploadBtnDiv').style.display = 'none';
  document.getElementById('deleteBtnDiv').style.display = 'block';
  event.currentTarget.getElementsByTagName('img')[0].src;
  document.getElementById(
    'image_Preview'
  ).src = event.currentTarget.getElementsByTagName('img')[0].src;

  document.getElementsByClassName(
    'preview-header'
  )[0].innerHTML = event.currentTarget.getElementsByTagName('div')[0].innerHTML;
  document.getElementsByClassName('preview-header')[0].style.right = '142px';
  MainFileName = event.currentTarget.getElementsByTagName('input')[0].value;
  //alert(MainFileName);
  // document.getElementById('FileID').value = MainFileName;
  // alert(document.getElementById('FileID').value);
}

function ShowLoading() {
  try {
    document.getElementById('uploadingDiv').style.display = 'flex';
  } catch { }
}

function HideLoading() {
  try {
    document.getElementById('uploadingDiv').style.display = 'none';
  } catch { }
}

var MainFileName = '';

// function LoadKeyImages() {
//   const token = localStorage.getItem('token');
//   console.log(token, 'ggggggggg');
//   ShowLoading();
//   var formData = new FormData();
//   formData.append('TokenId', getKey());

//   const request = axios.get(
//     `https://demo.dcmcloud.com/keyImages/v1/keyImages/getKeyImages`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );

//   // upload progress event
//   addEventListener('progress', function(evt) {
//     // upload progress as percentage
//   });

//   // request finished event
//   addEventListener('load', function(e) {
//     //alert(JSON.stringify(request.response));
//     setTimeout(function() {
//       document.getElementsByClassName('current')[0].click();
//       var elements = document.getElementsByClassName('loaded');
//       while (elements.length > 0) {
//         elements[0].parentNode.removeChild(elements[0]);
//       }

//       var JsonData = JSON.parse(request.response);
//       for (let index = 0; index < JsonData.length; index++) {
//         var divElement = document.createElement('div');
//         divElement.className = 'view-key-preview loaded';
//         divElement.addEventListener('click', function() {
//           loadUploadedImage();
//         });

//         var imgElement = document.createElement('img');
//         imgElement.className = 'view-key-image';
//         imgElement.alt = 'Image Preview';
//         imgElement.src = JsonData[index].imageUrl;

//         var divChildElement = document.createElement('div');
//         divChildElement.className = 'image-header';
//         divChildElement.innerHTML = JsonData[index].displayName;

//         var inputElement = document.createElement('input');
//         inputElement.type = 'hidden';
//         inputElement.value = JsonData[index].fileName;

//         divElement.appendChild(imgElement);
//         divElement.appendChild(divChildElement);
//         divElement.appendChild(inputElement);
//         document.getElementById('KeyImagesDiv').appendChild(divElement);
//       }

//       HideLoading();
//     }, 1500);

//     // HTTP status message (200, 404 etc)
//     //alert(request.status);
//     // request.response holds response from the server
//     //alert(request.response);
//     //location.reload();
//   });

//   // send POST request to server
//   request.send(formData);
// }
const getAllImages = async () => {
  setLoading(true);
  var keyFolder = getAccessKeys().split('^');
  var token = localStorage.getItem('token'); // Retrieve the token from localStorage
  var keyformurl = getKey();
  // console.log(keyformurl, 'rahaatttt');
  if (!keyformurl || keyformurl === 'local') {
    keyformurl = localStorage.getItem('StudyInstanceUID');
  }

  fetch(
    `${KeyImages}/keyImages/v1/keyImages?SUID=${keyformurl}&AwsAccessKey=${keyFolder[1]
    }&AwsSecretAccessKey=${keyFolder[2]}&AwsBucket=${keyFolder[0]}`,
    {
      headers: {
        Authorization: 'Bearer ' + token, // Include the token in the Authorization header
      },
    }
  )
    .then(response => response.json())
    .then(data => {
      var abc = data.data;
      setAllFiles(abc);
      setLoading(false);
    });
};

function LoadKeyImages() {
  getAllImages();
  const token = localStorage.getItem('token');
  ShowLoading();
  // var suid = getKey();
  // console.log(suid, 'keyformurl1keyformurl1');
  var suid = getKey();
  if (!suid || suid === 'local') {
    suid = localStorage.getItem('StudyInstanceUID');
    // console.log(suid, 'SUID from localStorage');
  }
  // console.log(suid, 'keyformurl1keyformurl1');
  axios
    .get(
      `${KeyImages}/keyImages/v1/keyImages/getKeyImages?SUID=${suid}&AwsAccessKey=AKIAQOPLK6GF3AKQQMEJ&AwsSecretAccessKey=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&AwsBucket=dcmcloud-demo`,
      // requestOptions
      // formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      setTimeout(function () {
        document.getElementsByClassName('current')[0].click();
        var elements = document.getElementsByClassName('loaded');
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }

        var JsonData = response.data;
        for (let index = 0; index < JsonData.length; index++) {
          var divElement = document.createElement('div');
          divElement.className = 'view-key-preview loaded';
          divElement.addEventListener('click', function () {
            loadUploadedImage();
          });

          var imgElement = document.createElement('img');
          imgElement.className = 'view-key-image';
          imgElement.alt = 'Image Preview';
          imgElement.src = JsonData[index].imageUrl;

          var divChildElement = document.createElement('div');
          divChildElement.className = 'image-header';
          divChildElement.innerHTML = JsonData[index].displayName;

          var inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.value = JsonData[index].fileName;

          divElement.appendChild(imgElement);
          divElement.appendChild(divChildElement);
          divElement.appendChild(inputElement);
          document.getElementById('KeyImagesDiv').appendChild(divElement);
        }

        HideLoading();
      }, 1500);
    })
    .catch(function (error) {
      // Handle error
      // console.error('Error fetching key images:', error);
      HideLoading(); // Ensure loading indicator is hidden on error
    });
}

const ViewportDownloadForm = ({
  activeViewport,
  onClose,
  updateViewportPreview,
  enableViewport,
  disableViewport,
  toggleAnnotations,
  loadImage,
  downloadBlob,
  defaultSize,
  minimumSize,
  maximumSize,
  canvasClass,
}) => {
  const [t] = useTranslation('ViewportDownloadForm');

  const [filename, setFilename] = useState(DEFAULT_FILENAME);
  const [fileType, setFileType] = useState('jpg');
  const [allFiles, setAllFiles] = useState([]);
  // console.log(allFiles, "RRRRRRRR");
  const [loading, setLoading] = useState(true);
  const imageRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [uploadLocal, setUploadLocal] = useState([]);
  const [saveLocalImg, setSaveLocalImg] = useState(false);
  // console.log(uploadLocal, 'uploadLocaluploadLocal');
  const [showAnnotations, setShowAnnotations] = useState(true);

  const [keepAspect, setKeepAspect] = useState(true);
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: 1,
    height: 1,
  });

  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });
  // console.log(window.location.href.includes('xrad'), 'xradViewerdownload');
  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: defaultSize,
    height: defaultSize,
  });

  const saveOnLocal = src => {
    setUploadLocal(prev => [...prev, src]);
    setSaveLocalImg(true);
  };

  const deleteLocalImage = index => {
    const updatedImages = [...uploadLocal];
    updatedImages.splice(index, 1);
    setUploadLocal(updatedImages);
  };
  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: defaultSize,
    height: defaultSize,
  });

  // console.log('refsadadsadadds', viewportPreview.src);

  const [error, setError] = useState({
    width: false,
    height: false,
    filename: false,
  });

  const hasError = Object.values(error).includes(true);

  const refreshViewport = useRef(null);

  const downloadImage = () => {
    downloadBlob(
      filename || DEFAULT_FILENAME,
      fileType,
      viewportElement,
      downloadCanvas.ref.current
    );
  };

  const callCurrentImage = event => {
    document.getElementsByClassName('current')[0].click();
  };

  const loadCurrentImage = event => {
    document.getElementById('uploadBtnDiv').style.display = 'block';
    document.getElementById('deleteBtnDiv').style.display = 'none';
    event.currentTarget.getElementsByTagName('img')[0].src;
    document.getElementById(
      'image_Preview'
    ).src = event.currentTarget.getElementsByTagName('img')[0].src;

    document.getElementsByClassName(
      'preview-header'
    )[0].innerHTML = event.currentTarget.getElementsByTagName(
      'div'
    )[0].innerHTML;
    document.getElementsByClassName('preview-header')[0].style.right = '250px';
  };

  const deleteImage = SUID => {
    var keyFolder = getAccessKeys().split('^');

    setLoading(true);
    var token = localStorage.getItem('token'); // Retrieve the token from localStorage

    fetch(
      `${KeyImages}/keyImages/v1/keyImages?SUID=${SUID}&AwsAccessKey=${keyFolder[1]
      }&AwsSecretAccessKey=${keyFolder[2]}&AwsBucket=${keyFolder[0]}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token, // Include the token in the Authorization header
        },
      }
    ).then(response => {
      response.json();
      getAllImages();
    });
  };

  const getAllImages = async () => {
    setLoading(true);
    var keyFolder = getAccessKeys().split('^');
    var token = localStorage.getItem('token'); // Retrieve the token from localStorage
    var keyformurl = getKey();
    // console.log(keyformurl, 'rahaatttt');
    if (!keyformurl || keyformurl === 'local') {
      keyformurl = localStorage.getItem('StudyInstanceUID');
    }

    //debugger;
    // console.log(
    // keyformurl,
    //   keyFolder[1],
    //   keyFolder[2],
    //   keyFolder[0],
    //   'SUID from localStorage111'
    // );

    fetch(
      `${KeyImages}/keyImages/v1/keyImages?SUID=${keyformurl}&AwsAccessKey=${keyFolder[1]
      }&AwsSecretAccessKey=${keyFolder[2]}&AwsBucket=${keyFolder[0]}`,
      {
        headers: {
          Authorization: 'Bearer ' + token, // Include the token in the Authorization header
        },
      }
    )
      .then(response => response.json())
      .then(data => {
        var abc = data.data;
        setAllFiles(abc);
        setLoading(false);
      });
  };

  const uploadImage = async () => {
    // ShowLoading();
    var credentials = getAccessKeys().split('^');
    // console.log(credentials, 'credentialscredentialscredentials');
    setLoading(true);
    var Img1 = document.getElementById('image_Preview');
    const bodyData = {
      keyImages: Img1.src,
      // SUID: `FS/QATESCEN/${getKey()}`,
      SUID: getKey(),
      AwsAccessKey: credentials[1],
      AwsSecretAccessKey: credentials[2],
      AwsBucket: credentials[0],
    };
    var token = localStorage.getItem('token'); // Ret
    await fetch(`${KeyImages}/keyImages/v1/keyImages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(bodyData),
    }).then(async response => {
      var data = await response.json();
      getAllImages();
    });
  };

  /**
   * @param {object} event - Input change event
   * @param {string} dimension - "height" | "width"
   */
  const onDimensionsChange = (event, dimension) => {
    const oppositeDimension = dimension === 'height' ? 'width' : 'height';
    const sanitizedTargetValue = event.target.value.replace(/\D/, '');
    const isEmpty = sanitizedTargetValue === '';
    const newDimensions = { ...dimensions };
    const updatedDimension = isEmpty
      ? ''
      : Math.min(sanitizedTargetValue, maximumSize);

    if (updatedDimension === dimensions[dimension]) {
      return;
    }

    newDimensions[dimension] = updatedDimension;

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] = Math.round(
        newDimensions[dimension] * aspectMultiplier[oppositeDimension]
      );
    }

    // In current code, keepAspect is always `true`
    // And we always start w/ a square width/height
    setDimensions(newDimensions);

    // Only update if value is non-empty
    if (!isEmpty) {
      setViewportElementDimensions(newDimensions);
      setDownloadCanvas(state => ({
        ...state,
        ...newDimensions,
      }));
    }
  };

  const error_messages = {
    width: t('minWidthError'),
    height: t('minHeightError'),
    filename: t('emptyFilenameError'),
  };

  const renderErrorHandler = errorType => {
    if (!error[errorType]) {
      return null;
    }

    return <div className="input-error">{error_messages[errorType]}</div>;
  };

  const onKeepAspectToggle = () => {
    const { width, height } = dimensions;
    const aspectMultiplier = { ...aspectMultiplier };
    if (!keepAspect) {
      const base = Math.min(width, height);
      aspectMultiplier.width = width / base;
      aspectMultiplier.height = height / base;
      setAspectMultiplier(aspectMultiplier);
    }

    setKeepAspect(!keepAspect);
  };

  const validSize = value => (value >= minimumSize ? value : minimumSize);
  const loadAndUpdateViewports = useCallback(async () => {
    const { width: scaledWidth, height: scaledHeight } = await loadImage(
      activeViewport,
      viewportElement,
      dimensions.width,
      dimensions.height
    );

    // toggleAnnotations(showAnnotations, viewportElement);

    const scaledDimensions = {
      height: validSize(scaledHeight),
      width: validSize(scaledWidth),
    };

    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(
      viewportElement,
      downloadCanvas.ref.current,
      fileType
    );
    // console.log('dataUrldataUrl', dataUrl);
    setViewportPreview(state => ({
      ...state,
      src: dataUrl,
      width: validSize(viewportElementWidth),
      height: validSize(viewportElementHeight),
    }));
  }, [
    loadImage,
    activeViewport,
    viewportElement,
    dimensions.width,
    dimensions.height,
    validSize,
    updateViewportPreview,
    downloadCanvas.ref,
    fileType,
  ]);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  useEffect(() => {
    // console.log('runningImagefunction');
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [
    activeViewport,
    viewportElement,
    showAnnotations,
    dimensions,
    loadImage,
    toggleAnnotations,
    updateViewportPreview,
    fileType,
    downloadCanvas.ref,
    minimumSize,
    maximumSize,
    loadAndUpdateViewports,
  ]);

  useEffect(() => {
    LoadKeyImages();
    const { width, height } = dimensions;
    const hasError = {
      width: width < minimumSize,
      height: height < minimumSize,
      filename: !filename,
    };

    setError({ ...hasError });
  }, [dimensions, filename, minimumSize]);

  useEffect(() => {
    setLoading(true);
    getAllImages();
    //setUploadLocal(viewportPreview.src);
  }, [viewportPreview.src]);

  useEffect(() => {
    getAllImages();
  }, []);

  const downloadImage2 = () => {
    // console.log('running');
    if (imageRef.current) {
      const link = document.createElement('a');
      link.href = imageRef.current.src;
      // console.log(imageRef.current.src, 'imageRef');

      link.download = 'image.png';
      link.click();
    }
  };
  //console.log(studyInstanceId, "studyInstanceIdstudyInsta")
  // console.log(imageRef, 'imageRefTaimoro');
  return (
    <div className="ViewportDownloadForm ViewportDownloadFormMain ">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '70%',
          padding: '20px',
          borderBottom: '1px solid #c9c6c6',
        }}
      >
        <div
          className="ViewportDownloadFormListDiv"
          //style={{ width: !loading ? '25%' : '100%' }}
          style={{ width: '25%' }}
        >
          {window.location.pathname.includes('local') ? (
            uploadLocal.length > 0 ? (
              uploadLocal.map((item, index) => {
                return (
                  <div
                    style={{
                      borderRadius: '10px',
                      border: '2px solid #c9c6c6',
                      marginBottom: '20px',
                      lineHeight: '0px',
                      width: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                    key={index}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        top: '-15px',
                        right: '-15px',
                      }}
                      onClick={() => { deleteLocalImage(index) }}
                    >
                      <Icon
                        name="dcm-bin"
                        style={{
                          height: '50px',
                          width: '50px',
                          cursor: 'pointer',
                        }}
                      />
                    </span>
                    {/* {saveLocalImg && ( */}
                    <img
                      className="ViewportDownloadFormListDivImages"
                      src={item}
                      alt={t('imagePreview')}
                    />
                    {/* )} */}
                  </div>
                );
              })) : <h4 style={{ color: 'white' }}>No Local Images Found...</h4>
          ) : viewportPreview.src ? (
            Array.isArray(allFiles) && allFiles.length > 0 ? (
              allFiles.map(item => (
                <div
                  style={{
                    borderRadius: '10px',
                    border: '2px solid #c9c6c6',
                    marginBottom: '20px',
                    lineHeight: '0px',
                    width: '80%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  key={item.key}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: '-15px',
                      right: '-15px',
                    }}
                    onClick={() => deleteImage(`${item.key}`)}
                  >
                    <Icon
                      name="dcm-bin"
                      style={{
                        height: '50px',
                        width: '50px',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                  <img
                    src={`data:image/png;base64,${item.Body}`}
                    alt={item.key}
                    className="ViewportDownloadFormListDivImages"
                  />
                </div>
              ))
            ) : (
              <h4 style={{ color: 'white' }}>No Key Images Found...</h4>
            )
          ) : (
            <div className="loading-image">
              <Icon name="circle-notch" className="icon-spin" />
              {t('loadingPreview')}
            </div>
          )}
        </div>

        {viewportPreview.src ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '75%',
            }}
            className="keyImages"
          >
            {/* main image */}
            <img
              id="image_Preview"
              ref={imageRef}
              className="viewport-preview"
              src={viewportPreview.src}
              alt={t('imagePreview')}
              data-cy="image-preview"
              // data-cy="viewport-preview-img"
              height={
                window.innerWidth < 600
                  ? dimensions.height / 3
                  : dimensions.height / 2
              }
              width={
                window.innerWidth < 600
                  ? dimensions.width / 3
                  : dimensions.width / 2
              }
            />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="title">{t('formTitle')}</div>

      <div
        className={
          localStorage.getItem('dcmCloudTheme') === null ||
            localStorage.getItem('dcmCloudTheme') === 'white'
            ? 'file-info-container'
            : 'file-info-container BB'
        }
        data-cy="file-info-container"
      >
        <div className="dimension-wrapper">
          <div className="dimensions">
            <div className="width">
              <TextInput
                type="number"
                min={minimumSize}
                max={maximumSize}
                value={dimensions.width}
                label={t('imageWidth')}
                onChange={evt => onDimensionsChange(evt, 'width')}
                data-cy="image-width"
              />
              {renderErrorHandler('width')}
            </div>
            <div className="height">
              <TextInput
                type="number"
                min={minimumSize}
                max={maximumSize}
                value={dimensions.height}
                label={t('imageHeight')}
                onChange={evt => onDimensionsChange(evt, 'height')}
                data-cy="image-height"
              />
              {renderErrorHandler('height')}
            </div>
          </div>
          <div className="keep-aspect-wrapper">
            <button
              id="keep-aspect"
              className={classnames(
                'form-button btn',
                keepAspect ? 'active' : ''
              )}
              data-cy="keep-aspect"
              alt={t('keepAspectRatio')}
              onClick={onKeepAspectToggle}
            >
              <Icon
                name={keepAspect ? 'link' : 'unlink'}
                alt={keepAspect ? 'Dismiss Aspect' : 'Keep Aspect'}
              />
            </button>
          </div>
        </div>

        <div className="col widthAdjustOnMobile">
          <div className="file-name">
            <TextInput
              type="text"
              data-cy="file-name"
              value={filename}
              onChange={event => setFilename(event.target.value)}
              label={t('filename')}
              id="file-name"
            />
            {renderErrorHandler('filename')}
          </div>
          <div className="file-type">
            <Select
              value={fileType}
              data-cy="file-type"
              onChange={event => setFileType(event.target.value)}
              options={FILE_TYPE_OPTIONS}
              label={t('fileType')}
            />
          </div>
        </div>
        {/* <div className="keep-aspect-wrapper">
          <button
            id="keep-aspect"
            className={classnames(
              'form-button btn',
              keepAspect ? 'active' : ''
            )}
            data-cy="keep-aspect"
            alt={t('keepAspectRatio')}
            onClick={onKeepAspectToggle}
          >
            <Icon
              name={keepAspect ? 'link' : 'unlink'}
              alt={keepAspect ? 'Dismiss Aspect' : 'Keep Aspect'}
            />
          </button>
        </div> */}
        <div className="col">
          <div className="show-annotations">
            <label htmlFor="show-annotations" className="form-check-label">
              <input
                id="show-annotations"
                data-cy="show-annotations"
                type="checkbox"
                className="form-check-input"
                checked={showAnnotations}
                onChange={event => setShowAnnotations(event.target.checked)}
                onClick={callCurrentImage}
              />
              {'Annotations'}
            </label>
          </div>
        </div>
      </div>

      <div
        style={{
          height: viewportElementDimensions.height,
          width: viewportElementDimensions.width,
          position: 'absolute',
          left: '9999px',
        }}
        ref={ref => setViewportElement(ref)}
      >
        <canvas
          className={canvasClass}
          style={{
            height: downloadCanvas.height,
            width: downloadCanvas.width,
            display: 'block',
          }}
          width={downloadCanvas.width}
          height={downloadCanvas.height}
          ref={downloadCanvas.ref}
        ></canvas>
      </div>

      <div className="actions">
        <div className="action-save">
          <button
            // disabled={hasError}
            onClick={downloadImage2}
            className="btn btn-primary"
          // data-cy="download-btn"
          >
            {t('Buttons:Download')}
          </button>
        </div>
        <div id="uploadBtnDiv" className="action-save">
          <button
            disabled={hasError}
            //  onClick={uploadImage}
            // onClick={() => saveOnLocal(viewportPreview.src)}
            onClick={window.location.pathname.includes('local') ? () => saveOnLocal(viewportPreview.src) : uploadImage}
            className="btn btn-primary"
            data-cy="upload-btn"
          >
            {t('Buttons:Save')}
          </button>
        </div>
        <div className="action-cancel">
          <button
            type="button"
            data-cy="cancel-btn"
            className="btn btn-danger"
            onClick={onClose}
          >
            {t('Buttons:Cancel')}
          </button>
        </div>
        <div
          id="deleteBtnDiv"
          style={{ display: 'none' }}
          className="action-save"
        >
          <button
            disabled={hasError}
            // onClick={deleteImage}
            className="btn btn-danger"
            data-cy="delete-btn"
          >
            {t('Buttons:Delete')}
          </button>
        </div>
      </div>
    </div>
  );
};

ViewportDownloadForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeViewport: PropTypes.object,
  updateViewportPreview: PropTypes.func.isRequired,
  enableViewport: PropTypes.func.isRequired,
  disableViewport: PropTypes.func.isRequired,
  toggleAnnotations: PropTypes.func.isRequired,
  loadImage: PropTypes.func.isRequired,
  downloadBlob: PropTypes.func.isRequired,
  /** A default width & height, between the minimum and maximum size */
  defaultSize: PropTypes.number.isRequired,
  minimumSize: PropTypes.number.isRequired,
  maximumSize: PropTypes.number.isRequired,
  canvasClass: PropTypes.string.isRequired,
};

export default ViewportDownloadForm;
