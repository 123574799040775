import { importInternal, getToolState, addToolState } from 'cornerstone-tools';

const draw = importInternal('drawing/draw');
const drawLine = importInternal('drawing/drawLine');
const drawTextBox = importInternal('drawing/drawTextBox');
const getNewContext = importInternal('drawing/getNewContext');
const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');

class CTRRatioTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const cursors = cornerstoneTools.importInternal('tools/cursors');
    const cursor = cursors.arrowAnnotateCursor; // `MouseCursor` class
    const defaultProps = {
      name: 'CTRRatioTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        shadowColor: '#000000',
        shadowOffsetX: 1,
        shadowOffsetY: 1,
      },
      svgCursor: cursor,
    };

    super(props, defaultProps);
  }

  static distanceFromLine(element, start, end, coords) {
    const columnPixelSpacing = 1; // Replace with actual spacing if available
    const rowPixelSpacing = 1; // Replace with actual spacing if available

    const dx = (end.x - start.x) * columnPixelSpacing;
    const dy = (end.y - start.y) * rowPixelSpacing;
    const length = Math.sqrt(dx * dx + dy * dy);

    if (length === 0)
      return Math.sqrt(
        (coords.x - start.x) * (coords.x - start.x) +
        (coords.y - start.y) * (coords.y - start.y)
      );

    const t = ((coords.x - start.x) * dx + (coords.y - start.y) * dy) / (length * length);
    const closestX = start.x + t * dx;
    const closestY = start.y + t * dy;
    const distance = Math.sqrt(
      (coords.x - closestX) * (coords.x - closestX) +
      (coords.y - closestY) * (coords.y - closestY)
    );

    return distance;
  }

  createNewMeasurement(eventData) {
    return {
      visible: true,
      active: true,
      color: undefined,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        end: {
          x: eventData.currentPoints.image.x + 10, // Default length
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const { start, end } = data.handles;
    const distance = this.constructor.distanceFromLine(element, start, end, coords);
    return distance < 5;
  }



  /* renderToolData(evt) {
    const eventData = evt.detail;
    const { element, canvasContext, image } = eventData;
    const toolData = getToolState(element, this.name);

    if (!toolData) {
      return;
    }

    const context = getNewContext(canvasContext.canvas);
    const { configuration } = this;
    const color = cornerstoneTools.toolColors.getToolColor();
    const lineWidth = cornerstoneTools.toolStyle.getActiveWidth();
    const options = { color, lineWidth, ...configuration };

    toolData.data.forEach((data, index) => {
      const { start, end } = data.handles;
      draw(context, (context) => {
        drawLine(context, element, start, end, options);
      });

      // Display the length of each line in cm
      const length = this.calculateLength(element, data.handles);
      const lengthText = `${(length / 10).toFixed(2)} cm`; // Assuming 1 pixel = 0.1 cm for example
      const textCoords = {
        x: end.x,
        y: end.y,
      };
      draw(context, (context) => {
        drawTextBox(context, lengthText, textCoords.x, textCoords.y, color);
      });

      if (index % 2 === 1) {
        // Draw a link line between the two measurements
        const prevData = toolData.data[index - 1];
        const linkStart = prevData.handles.end;
        const linkEnd = data.handles.start;
        draw(context, (context) => {
          drawLine(context, element, linkStart, linkEnd, { color: 'red', lineWidth });
        });

        // Calculate and display the CTR ratio
        const length1 = this.calculateLength(element, prevData.handles);
        const length2 = this.calculateLength(element, data.handles);
        const ratio = ((length1 / length2) * 100).toFixed(2) + '%';

        const ratioCoords = {
          x: (data.handles.start.x + data.handles.end.x) / 2 + 50,
          y: (data.handles.start.y + data.handles.end.y) / 2 + 50,
        };

        draw(context, (context) => {
          drawTextBox(context, `CTR Ratio: ${ratio}`, ratioCoords.x, ratioCoords.y, 'red');
        });
      }
    });
  } */

  renderToolData(evt) {
    const eventData = evt.detail;
    const { element, canvasContext, image } = eventData;
    const toolData = getToolState(element, this.name);

    if (!toolData) {
      return;
    }

    const context = getNewContext(canvasContext.canvas);
    const { configuration } = this;
    const color = cornerstoneTools.toolColors.getToolColor();
    const lineWidth = cornerstoneTools.toolStyle.getActiveWidth();
    const options = { color, lineWidth, ...configuration };

    const drawArrow = (context, from, to, color) => {
      const headLength = 10; // Length of the arrow head in pixels
      const dx = to.x - from.x;
      const dy = to.y - from.y;
      const angle = Math.atan2(dy, dx);

      context.beginPath();
      context.moveTo(from.x, from.y);
      context.lineTo(to.x, to.y);
      context.strokeStyle = color;
      context.lineWidth = lineWidth;
      context.stroke();

      context.beginPath();
      context.moveTo(to.x, to.y);
      context.lineTo(to.x - headLength * Math.cos(angle - Math.PI / 6), to.y - headLength * Math.sin(angle - Math.PI / 6));
      context.lineTo(to.x - headLength * Math.cos(angle + Math.PI / 6), to.y - headLength * Math.sin(angle + Math.PI / 6));
      context.lineTo(to.x, to.y);
      context.lineTo(to.x - headLength * Math.cos(angle - Math.PI / 6), to.y - headLength * Math.sin(angle - Math.PI / 6));
      context.strokeStyle = color;
      context.lineWidth = lineWidth;
      context.stroke();
      context.fillStyle = color;
      context.fill();
    };

    toolData.data.forEach((data, index) => {
      const { start, end } = data.handles;

      draw(context, (context) => {
        drawLine(context, element, start, end, options);
      });

      // Display the length of each line in cm
      const length = this.calculateLength(element, data.handles);
      const lengthText = `${(length / 10).toFixed(2)} cm`; // Assuming 1 pixel = 0.1 cm for example
      const textCoords = {
        x: (end.x + start.y) / 2 + 180,
        y: (end.y + start.y) / 2 + 10,
      };
      draw(context, (context) => {
        drawTextBox(context, lengthText, textCoords.x, textCoords.y, color);
      });

      if (index % 2 === 1) {
        // Draw a link line between the two measurements
        const prevData = toolData.data[index - 1];
        const linkStart = prevData.handles.end;
        const linkEnd = data.handles.start;
        draw(context, (context) => {
          context.setLineDash([5, 5]); // Set the line dash pattern to create a dotted line
          drawLine(context, element, linkStart, linkEnd, { color: '#27ff00', lineWidth });
          context.setLineDash([]); // Reset the line dash pattern to solid
        });

        // Calculate and display the CTR ratio
        const length1 = this.calculateLength(element, prevData.handles);
        const length2 = this.calculateLength(element, data.handles);
        const ratio = ((length1 / length2) * 100).toFixed(2) + '%';

        const ratioCoords = {
          x: (linkStart.x + linkEnd.x) / 2 + 170,
          y: (linkStart.y + linkEnd.y) / 2, // Position the ratio text in the center of the red line
        };

        draw(context, (context) => {
          drawTextBox(context, `CTR Ratio: ${ratio}`, ratioCoords.x, ratioCoords.y, '#27ff00');
        });
      }
    });
  }

  // renderToolData(evt) {
  //   const eventData = evt.detail;
  //   const { element, canvasContext, image } = eventData;
  //   const toolData = getToolState(element, this.name);

  //   if (!toolData) {
  //     return;
  //   }

  //   const context = getNewContext(canvasContext.canvas);
  //   const { configuration } = this;
  //   const color = cornerstoneTools.toolColors.getToolColor();
  //   const lineWidth = cornerstoneTools.toolStyle.getActiveWidth();
  //   const options = { color, lineWidth, ...configuration };

  //   const drawArrow = (context, from, to, color) => {
  //     const headLength = 10; // Length of the arrow head in pixels
  //     const dx = to.x - from.x;
  //     const dy = to.y - from.y;
  //     const angle = Math.atan2(dy, dx);

  //     context.beginPath();
  //     context.moveTo(from.x, from.y);
  //     context.lineTo(to.x, to.y);
  //     context.strokeStyle = color;
  //     context.lineWidth = lineWidth;
  //     context.stroke();

  //     context.beginPath();
  //     context.moveTo(to.x, to.y);
  //     context.lineTo(to.x - headLength * Math.cos(angle - Math.PI / 6), to.y - headLength * Math.sin(angle - Math.PI / 6));
  //     context.lineTo(to.x - headLength * Math.cos(angle + Math.PI / 6), to.y - headLength * Math.sin(angle + Math.PI / 6));
  //     context.lineTo(to.x, to.y);
  //     context.lineTo(to.x - headLength * Math.cos(angle - Math.PI / 6), to.y - headLength * Math.sin(angle - Math.PI / 6));
  //     context.strokeStyle = color;
  //     context.lineWidth = lineWidth;
  //     context.stroke();
  //     context.fillStyle = color;
  //     context.fill();
  //   };

  //   toolData.data.forEach((data, index) => {
  //     const { start, end } = data.handles;
  //     draw(context, (context) => {
  //       drawArrow(context, start, end, color);
  //       drawArrow(context, end, start, color);
  //     });

  //     // Display the length of each line in cm
  //     const length = this.calculateLength(element, data.handles);
  //     const lengthText = `${(length / 10).toFixed(2)} cm`; // Assuming 1 pixel = 0.1 cm for example
  //     const textCoords = {
  //       x: (end.x + start.y) / 2 + 180,
  //       y: (end.y + start.y) / 2 + 10,
  //     };
  //     draw(context, (context) => {
  //       drawTextBox(context, lengthText, textCoords.x, textCoords.y, color);
  //     });

  //     if (index % 2 === 1) {
  //       // Draw a link line between the two measurements
  //       const prevData = toolData.data[index - 1];
  //       const linkStart = prevData.handles.end;
  //       const linkEnd = data.handles.start;
  //       draw(context, (context) => {
  //         context.setLineDash([5, 5]); // Set the line dash pattern to create a dotted line
  //         drawLine(context, element, linkStart, linkEnd, { color: 'red', lineWidth });
  //         context.setLineDash([]); // Reset the line dash pattern to solid
  //       });

  //       // Calculate and display the CTR ratio
  //       const length1 = this.calculateLength(element, prevData.handles);
  //       const length2 = this.calculateLength(element, data.handles);
  //       const ratio = ((length1 / length2) * 100).toFixed(2) + '%';

  //       const ratioCoords = {
  //         x: (linkStart.x + linkEnd.x) / 2 + 170,
  //         y: (linkStart.y + linkEnd.y) / 2, // Position the ratio text in the center of the red line
  //       };

  //       draw(context, (context) => {
  //         drawTextBox(context, `CTR Ratio: ${ratio}`, ratioCoords.x, ratioCoords.y, 'red');
  //       });
  //     }
  //   });
  // }


  calculateLength(element, handles) {
    const { start, end } = handles;
    return Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
  }
}

export default CTRRatioTool;
