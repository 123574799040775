import React from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';

import AppContext from '../../../../viewer/src/context/AppContext.js';

function StudyBrowser(props) {
  const { setSeriesDescription } = React.useContext(AppContext);
  const {
    studies,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
  } = props;

  const omClickThumbnail = SeriesDescription => {
    setSeriesDescription(SeriesDescription);
    // console.log('SeriesDescription', SeriesDescription);
  };

  // const omClickThumbnail = displaySetInstanceUID => {
  //   onThumbnailClick(displaySetInstanceUID);
  //   console.log('displaySetInstanceUID', displaySetInstanceUID);
  // };

  return (
    <div className="scrollable-study-thumbnails">
      {studies
        .map((study, studyIndex) => {
          const { StudyInstanceUID } = study;
          return study.thumbnails.map((thumb, thumbIndex) => {
            // TODO: Thumb has more props than we care about?
            const {
              active,
              altImageText,
              displaySetInstanceUID,
              imageId,
              InstanceNumber,
              numImageFrames,
              SeriesDescription,
              SeriesNumber,
              stackPercentComplete,
              hasWarnings,
            } = thumb;

            return (
              <div
                key={thumb.displaySetInstanceUID}
                className="thumbnail-container"
                data-cy="thumbnail-list"
              // onClick={omClickThumbnail(SeriesDescription)}
              >
                <Thumbnail
                  active={active}
                  supportsDrag={supportsDrag}
                  key={`${studyIndex}_${thumbIndex}`}
                  id={`${studyIndex}_${thumbIndex}`} // Unused?
                  // Study
                  StudyInstanceUID={StudyInstanceUID} // used by drop
                  // Thumb
                  altImageText={altImageText}
                  imageId={imageId}
                  InstanceNumber={InstanceNumber}
                  displaySetInstanceUID={displaySetInstanceUID} // used by drop
                  numImageFrames={numImageFrames}
                  SeriesDescription={SeriesDescription}
                  SeriesNumber={SeriesNumber}
                  hasWarnings={hasWarnings}
                  stackPercentComplete={stackPercentComplete}
                  // Events
                  onClick={() => {
                    // Existing function call
                    onThumbnailClick(displaySetInstanceUID);

                    // Add your additional function call here
                    omClickThumbnail(SeriesDescription);
                  }}
                  onDoubleClick={onThumbnailDoubleClick}
                />
              </div>
            );
          });
        })
        .flat()}
    </div>
  );
}

const noop = () => { };

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          InstanceNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
};

export { StudyBrowser };
