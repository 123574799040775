
export const Fileloader = 'https://demo.dcmcloud.com'; //'https://demo.dcmcloud.com'; 6007

// this is for KeyImages based on node.js
export const KeyImages = 'https://demo.dcmcloud.com'; //'https://demo.dcmcloud.com';8001

// this is for login and payment based on node.js
export const LoginApi = 'https://demo.dcmcloud.com'; //'https://demo.dcmcloud.com';8000

// this is for upload-dicom based on node.js
export const UploadApi = 'https://demo.dcmcloud.com';

// this is meta_data and fileLoader
export const MetaPython = 'https://demo.dcmcloud.com'; //'https://demo.dcmcloud.com';8011

// this is coreApi based on .net
export const CoreApi = 'https://demo.dcmcloud.com'; //'https://demo.dcmcloud.com'; 6008

// this is annotation based on node.js
export const AnnotaionApi = 'https://demo.dcmcloud.com';
