import React, { useContext, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getActiveContexts } from '../store/layout/selectors.js';

let AppContext = React.createContext({});

export const CONTEXTS = {
  CORNERSTONE: 'ACTIVE_VIEWPORT::CORNERSTONE',
  VTK: 'ACTIVE_VIEWPORT::VTK',
};

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children, config }) => {
  const activeContexts = useSelector(state => getActiveContexts(state));
  const [seriesDescription, setSeriesDescription] = useState('');

  const [selectedLabel, setSelectedLabel] = useState(null);
  const [direction, setDirection] = useState('ascend');
  const [excludedLabels, setExcludedLabels] = useState([]);
  const [isExcludeMode, setIsExcludeMode] = useState(false);

  const toolbarRef = useRef(null); // Add the toolbarRef here

  return (
    <AppContext.Provider
      value={{
        appConfig: config,
        activeContexts,
        seriesDescription,
        setSeriesDescription,

        selectedLabel,
        setSelectedLabel,

        direction,
        setDirection,

        excludedLabels,
        setExcludedLabels,

        isExcludeMode,
        setIsExcludeMode,

        toolbarRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const withAppContext = Component => {
  return function WrappedComponent(props) {
    const { appConfig, activeContexts, toolbarRef } = useAppContext();
    const [isMPR2D, setIsMPR2D] = useState(false);
    const [isState3d, setIsState3d] = useState(false);
    return (
      <Component
        {...props}
        appConfig={appConfig}
        activeContexts={activeContexts}
        setIsMPR2D={setIsMPR2D}
        setIsState3d={setIsState3d}
        isMPR2D={isMPR2D}
        isState3d={isState3d}
        toolbarRef={toolbarRef}
      />
    );
  };
};

export default AppContext;
